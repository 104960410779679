import "core-js/modules/es6.array.map.js";
export default defineComponent({
  props: {
    value: {
      type: Object,
      default: {}
    }
  },
  methods: {
    onChange: function onChange(value) {
      var result = null;
      if (value instanceof Array) {
        result = value.map(function (v) {
          if (typeof v === 'string') {
            return {
              name: v
            };
          } else {
            return v;
          }
        });
      } else if (typeof value === 'string') {
        result = {
          name: value
        };
      } else {
        result = value;
      }
      this.$emit('input', result);
    }
  }
});